<template>
  <b-overlay :show="busy" rounded="sm">
    <ValidationObserver ref="form" >
      <form @submit.prevent="handleSubmit" id="frmSchool">
        <b-card class="editschool" :title="isUpdateRequest ? 'Update School' : 'Add New School'">
          <b-card-body class="py-0">
            <p>
              <small>All fields with <span class="text-danger">*</span> are required.</small>
            </p>
            <div class="row">
               <div class="col-md-12">
                <div class="form-group">
                  <label>Name:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <input v-model="school.name" name="schoolName" class="form-control" type="text" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
               <div class="col-md-6">
                <div class="form-group">
                  <label>Email:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                    <input v-model="school.email" name="schoolEmail" class="form-control" type="email" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
               <div class="col-md-6">
                <div class="form-group">
                  <label>Telephone:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Telephone" rules="required" v-slot="{ errors }">
                    <input v-model="school.telephone" name="schoolTelephone" class="form-control" type="text" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Cover Image URL:</label>
                  <ValidationProvider name="Cover Image" rules="url" v-slot="{ errors }" mode="eager">
                    <input v-model="school.coverimage" name="CoverImage" class="form-control" type="url" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <b-img :src="school.coverimage" class="thumbnail" v-if="school.coverimage" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Address:</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                    <textarea v-model="school.address" name="schoolAddress" class="form-control" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>School Administrator(s):</label><span class="ml-1 text-danger">*</span>
                  <ValidationProvider name="School Admin" rules="required" v-slot="{ errors }">
                    <UserPicker v-model="school.school_admins" name="schoolAdmin" />
                    <span class="text-danger form-text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </b-card-body>
          <template v-slot:footer>
            <button class="btn btn-primary" type="submit" :disabled="submitting">
              <b-spinner small v-if="submitting" />
             {{ isUpdateRequest ? 'Update School' : 'Add School' }}
            </button>
            <button class="btn btn-danger" type="reset" :disabled="submitting" @click.prevent="reset">Reset</button>
          </template>
        </b-card>
      </form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/util/validation';
import UserPicker from '@/components/ui/UserPicker.vue';

export default {
  name: 'EditSchoolForm',
  components: { ValidationProvider, ValidationObserver, UserPicker },
  props: {
    value: {
      type: Object,
      default: null,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      school: {},
      submitting: false,
    };
  },

  computed: {
    isUpdateRequest() {
      return this.value && this.value.id;
    },
  },

  methods: {
    handleSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return this.$toast.error('Please check your input', 'Form Not Valid');
        const newSchool = { ...this.school };
        newSchool.school_admin_ids = newSchool.school_admins ? newSchool.school_admins.map(admin => admin.id) : [];
        if (this.isUpdateRequest) this.updateSchool(newSchool);
        else this.addSchool(newSchool);
        return false;
      });
    },
    addSchool(newSchool) {
      this.submitting = true;
      this.$store.dispatch('school/addSchool', [newSchool]).then(() => {
        const msg = 'School Added Successfully';
        this.$toast.success(msg);
        this.reset();
      }).catch(() => {
        this.$toast.error('Cannot add School. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    updateSchool(updSchool) {
      this.submitting = true;
      this.$store.dispatch('school/updateSchool', [updSchool]).then(() => {
        const msg = 'School Updated Successfully';
        this.$toast.success(msg);
      }).catch(() => {
        this.$toast.error('Cannot update School. Try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
    reset() {
      this.$refs.form.reset();
      this.school = Object.assign({}, this.value);
    },
  },

  mounted() {
    this.reset();
  },
};
</script>

<style lang="stylus" scoped>
.editschool
 .form-group
   margin-bottom 0.5rem
   label
    margin-bottom 0
 .btn
   margin-right 1rem
 .thumbnail
   width 3rem
   margin 0.5rem 0.5rem 0 0

</style>
