var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.busy,"rounded":"sm"}},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"id":"frmSchool"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-card',{staticClass:"editschool",attrs:{"title":_vm.isUpdateRequest ? 'Update School' : 'Add New School'},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.submitting}},[(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isUpdateRequest ? 'Update School' : 'Add School')+" ")],1),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"reset","disabled":_vm.submitting},on:{"click":function($event){$event.preventDefault();return _vm.reset($event)}}},[_vm._v("Reset")])]},proxy:true}])},[_c('b-card-body',{staticClass:"py-0"},[_c('p',[_c('small',[_vm._v("All fields with "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" are required.")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.school.name),expression:"school.name"}],staticClass:"form-control",attrs:{"name":"schoolName","type":"text"},domProps:{"value":(_vm.school.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.school, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.school.email),expression:"school.email"}],staticClass:"form-control",attrs:{"name":"schoolEmail","type":"email"},domProps:{"value":(_vm.school.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.school, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Telephone:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.school.telephone),expression:"school.telephone"}],staticClass:"form-control",attrs:{"name":"schoolTelephone","type":"text"},domProps:{"value":(_vm.school.telephone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.school, "telephone", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cover Image URL:")]),_c('ValidationProvider',{attrs:{"name":"Cover Image","rules":"url","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.school.coverimage),expression:"school.coverimage"}],staticClass:"form-control",attrs:{"name":"CoverImage","type":"url"},domProps:{"value":(_vm.school.coverimage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.school, "coverimage", $event.target.value)}}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.school.coverimage)?_c('b-img',{staticClass:"thumbnail",attrs:{"src":_vm.school.coverimage}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Address:")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.school.address),expression:"school.address"}],staticClass:"form-control",attrs:{"name":"schoolAddress"},domProps:{"value":(_vm.school.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.school, "address", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("School Administrator(s):")]),_c('span',{staticClass:"ml-1 text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"name":"School Admin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UserPicker',{attrs:{"name":"schoolAdmin"},model:{value:(_vm.school.school_admins),callback:function ($$v) {_vm.$set(_vm.school, "school_admins", $$v)},expression:"school.school_admins"}}),_c('span',{staticClass:"text-danger form-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }