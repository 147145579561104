<template>
   <div>
    <BreadCrumb :items="breadCrumb" />
    <EditSchoolForm :value="school"  />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditSchoolForm from '@/components/form/EditSchoolForm.vue';

export default {
  name: 'CreateSchool',
  components: { BreadCrumb, EditSchoolForm },
  data() {
    return {
      school: {},
    };
  },
  computed: {
    breadCrumb() {
      return [
        { text: 'School List', to: { name: 'bke-school-list' } },
        { text: 'New', active: true },
      ];
    },
  },

};
</script>
